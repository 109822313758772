.avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #ccc;
    margin-right: 10px;
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}